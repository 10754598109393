import React from 'react'
import { Helmet } from 'react-helmet'
// import { Link } from "gatsby"

import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Intro from '../components/sections/intro'
import HomeContent from '../components/sections/home-content'
import Partners from '../components/sections/partners'
import Zip from '../components/apply/zip'

const IndexPage = ({ location }) => (
  <Layout phone="877-755-6327" location={location}>
    <Helmet>
      <script>{`window.initiateJquery();`}</script>
    </Helmet>
    <SEO
      title="Life Insurance Quotes - Life Insurance Companion"
      keywords={[`health`, `insurance`, `plans`, `affordable`]}
    />
    <Header phone="877-755-6327" />
    <Intro showTitle={true} showInfoBox={true}>
      <div className="form-box">
        <div className="contacts-form alt text-center">
          <Zip shouldNavigate={true} />
        </div>
      </div>
    </Intro>
    <main id="main">
      <Partners />
      <HomeContent />
    </main>
  </Layout>
)

export default IndexPage
